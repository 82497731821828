import AppFooter from '@/app/ui-new/components/Navigation/AppFooter/AppFooter'
import React, { useEffect } from 'react'
import RequestDemo from '@/app/ui/pages/components/RequestDemo/RequestDemo'
import RootLayout from '@/app/ui-new/layouts/RootLayout'
import { AppHeader } from '@/app/ui-new/components/Navigation/AppHeader/AppHeader'
import { InProductionAlertBanner } from '@/app/ui-new/layouts/components/AlertBanner/InProduction'
import { IncompleteProfileAlertBanner } from '@/app/ui-new/layouts/components/AlertBanner/IncompleteProfile'
import { LayoutProps } from '@/app/ui/routes'
import { MarketPlaceHeader } from '@/app/ui-new/components/MarketPlaceHeader/MarketPlaceHeader'
import { MissingDefaultBillingAddressAlertBanner } from '@/app/ui-new/layouts/components/AlertBanner/MissingDefaultBillingAddress'
import { UserRole } from '@lazr/openapi-client'
import { WebsiteHeaderDesktop } from '@/app/ui-new/components/Navigation/WebsiteHeader/WebsiteHeaderDesktop'
import { env, Environment } from '@/app/config'
import { getDefaultAddressList as reduxGetDefaultAddressList } from '@/app/ui/redux/selectors'
import { getUser as reduxGetUser } from '@/app/ui/redux/selectors/UserSelectors'
import { setMarketPlaceStep as reduxSetMarketPlaceStep } from '@/app/ui-new/redux/actions/MarketPlaceActions'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { getMarketPlaceStepFromPath, getMarketPlaceStepPath } from '@/app/ui-new/pages/marketplace/helpers/marketplaceHelpers'

import { AppBody, AppContent, AppWrapper } from './MarketPlaceLayout.styled'

import { getIsDefaultBillingAddressesCallExecuted as reduxGetIsDefaultBillingAddressesCallExecuted } from '@/app/ui/redux/selectors/AddressListApiCallExecutedSelectors'
import SupportWidget from '@/app/ui-new/components/SupportWidget/SupportWidget'
import { getCurrentStep as reduxGetCurrentStep } from '@/app/ui-new/redux/selectors/MarketPlaceSelectors'
import { getVersionSnackbarOpen as reduxGetVersionSnackbarOpen } from '@/app/ui/redux/selectors/VersionSelectors'
import Cookies from 'js-cookie'
import NotistackAlerts from '@/app/ui-new/layouts/NotistackAlerts'
import { BestExperienceNotistackAlert } from './components/NotistackAlert/BestExperience'

export const MarketPlaceLayout: React.FunctionComponent<Props> = ({ children }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const user = useSelector(reduxGetUser)
    const currentStep = useSelector(reduxGetCurrentStep)
    const defaultAddressList = useSelector(reduxGetDefaultAddressList)
    const isDefaultBillingAddressesCallExecuted = useSelector(reduxGetIsDefaultBillingAddressesCallExecuted)
    const versionSnackbarOpen = useSelector(reduxGetVersionSnackbarOpen)
    const cookiesSnakbarOpen = Cookies.get('cookie-law-agreed') !== 'true'

    const isProduction = env === Environment.PRODUCTION
    const isUserDeveloper = user && user.isDeveloper
    const showProductionWarning = isProduction && isUserDeveloper
    const isUserProfileComplete = user?.firstName && user.lastName
    const hasDefaultBillingAddress = user && defaultAddressList && defaultAddressList.billingAddressList.length > 0
    const isOrganizationAdmin =
        (user?.permissions.organizationRole.role === UserRole.ADMIN || user?.getImpersonatingInfo().isAdmin) ?? false

    useEffect(() => {
        if (!window.location.href.includes('/beta/marketplace/load-and-terms')) {
            if (!user) {
                history.push('/signin')

                return
            }
        }

        const stepFromPath = getMarketPlaceStepFromPath(location.pathname)
        const maxAllowedStep = +(localStorage.getItem('newMarketPlaceLastCompletedStep') || 0) + 1

        // TODO: (GD) The code here under has been commented out to support pasted url in the browser.
        /*if (stepFromPath > maxAllowedStep) {
            dispatch(reduxSetMarketPlaceStep(maxAllowedStep))

            history.replace(
                getMarketPlaceStepPath(maxAllowedStep, location.pathname) + (location.search as string) + (location.hash as string),
            )
        } else {*/
            const storedStep = +(localStorage.getItem('newMarketPlaceStep') || 0)

            if (stepFromPath !== storedStep) {
                dispatch(reduxSetMarketPlaceStep(stepFromPath))
            } else {
                dispatch(reduxSetMarketPlaceStep(storedStep))
            }
        /*}*/
    }, [location, currentStep, user])

    return RootLayout({
        children: (
            <AppWrapper>
                {user && <AppHeader user={user} />}
                {!user && (
                    <>
                        <WebsiteHeaderDesktop user={user} />
                        <RequestDemo />
                    </>
                )}
                <MarketPlaceHeader />
                <AppBody id='app-body'>
                    <AppContent key={location.key}>
                        {showProductionWarning && <InProductionAlertBanner />}
                        {!hasDefaultBillingAddress && isDefaultBillingAddressesCallExecuted && (
                            <MissingDefaultBillingAddressAlertBanner isOrganizationAdmin={isOrganizationAdmin} />
                        )}
                        {user && !isUserProfileComplete && <IncompleteProfileAlertBanner />}
                        <BestExperienceNotistackAlert/>
                        <NotistackAlerts versionSnackbarOpen={versionSnackbarOpen} cookiesSnakbarOpen={cookiesSnakbarOpen} />
                        {children}
                    </AppContent>
                    <SupportWidget />
                    <AppFooter />
                </AppBody>
            </AppWrapper>
        ),
    })
}

export interface Props extends LayoutProps {}

export default MarketPlaceLayout
